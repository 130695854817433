$main-color: #ef520d;
$plain-text-color: #3b3b3b;
$link-color: $main-color;
$content-divider-color: #e1e1e1;
$title-text-color: #fff;
$titlebox-bg-color: $plain-text-color;
$titlebox-border-color: #2e2e2e;
$breadcrumbs-bg-color: $main-color;
$breadcrumbs-highlight-color: adjust-hue($main-color,5deg * 0.01);
$box-bottom-border-color: $breadcrumbs-highlight-color;
$navbar-default-outline: #f1f2f2;
$navbar-text-color: $plain-text-color;
$subnav-bg-color: $plain-text-color;
$subnav-bottom-border-color: $breadcrumbs-highlight-color;
$subnav-text-color: $title-text-color;
$subnav-hover-color: $main-color;
$subnav-divider-color: darken($navbar-default-outline,50%);
$footer-text-color: #919090;
$footer-link-text-color: $main-color;
$footer-link-text-hover-color: $footer-text-color;
$footer-header-text-color: $title-text-color;
$footer-nav-text-color: $footer-text-color;
$footer-nav-divider-color: $footer-nav-text-color;
$footer-tweet-text-link-hover-color: $title-text-color;
$blockquote-text-color: #7a7a7a;
$cite-text-color: $main-color;
$button-bg-color: $main-color;
$button-text-color: $title-text-color;
$error-color: #f00;
$error-message-bg-color: #ff0;

$mobile-navbar-bg-color: $navbar-default-outline;
$mobile-navbar-item-active-color: $main-color;
$mobile-subnav-bg-color: $mobile-navbar-bg-color;
$mobile-subnav-text-color: $plain-text-color;

