@mixin imgtxt {
	background: no-repeat;
	display: block;
	font-size: .01em;
	overflow: hidden;
	text-indent: -99999px;
}
@mixin clearing {
	&:after {
		content: ".";
		display: block;
		clear: both;
		visibility: hidden;
		line-height: 0;
		height: 0;
	}
}
body {
	font: 15px/22px Arial, Helvetica, sans-serif;
}
a {
	color: $link-color;
}
h1, h2, h3, h4, h5, h6 {
	color: $plain-text-color;
	font-family: 'Droid Sans', serif;
	font-weight: normal;
	line-height: normal;
	padding-bottom: 10px;
}
h5 {
	font-size: 16px;
}
h4 {
	font-size: 19px;
	text-transform: uppercase;
}
h3 {
	font-size: 22px;
}
h1 {
	color: $title-text-color;
	font-size: 30px;
	line-height: normal;
	padding: 25px 0;
	text-transform: uppercase;
}
span {
	color: $main-color;
}
blockquote {
	color: $blockquote-text-color;
	display: block;
	font-family: "Times New Roman",Times,serif;
	font-style: italic;
	margin: 0;
	padding: 0;
}
cite {
	color: $cite-text-color;
	display: block;
	font-style: italic;
	text-align: right;
}
button {
	background: $button-bg-color;
	border: none;
	border-radius: 3px;
	color: $button-text-color;
	margin-bottom: 10px;
}
