/* THESE STYLES NO NEED TO CHANGE */

$images-root: "/cdn-cgi/image/format=auto,onerror=redirect/img";

@import 'reset.scss';
@import 'params.scss';
@import 'base.scss';
@import url(//fonts.googleapis.com/css?family=Droid+Sans) screen;
.box {
	border: 1px solid $content-divider-color;
	border-bottom: 6px solid $box-bottom-border-color;
	text-align: center;
	display: block;
	padding: 20px 20px 30px 20px;
	min-height: 285px;
	> h4 {
		color: $main-color;
	}
}
.section-title {
	background: url($images-root + "/misc/title_icon.png") no-repeat left center;
	border-bottom: 1px solid $content-divider-color;
	margin-bottom: 20px;
	padding-bottom: 20px;
	> h3 {
		padding: 23px 0 0 55px;
		padding-left: 55px;
		padding-top: 23px;
	}
}

.navbar-inner {
	background: none;
	border: none;
	box-shadow: none;
	.main-nav {
		z-index: 9999;
	}
	.main-nav, .footer-nav {
		float: right;
		@include clearing;
		> li {
			float: left;
			.nav-title {
				background: url($images-root + "/misc/nav_background_1.jpg") repeat-x bottom;
				border-right: 1px solid $navbar-default-outline;
				display: block;
				margin: 0;
				padding: 25px 12px;
				color: $navbar-text-color;
				display: block;
				font: 16px 'Droid Sans', serif;
				text-align: center;
				text-transform: uppercase;
				margin-bottom: 5px;
			}
			&:first-child {
				.nav-title {
					border-left: 1px solid $navbar-default-outline;
				}
			}
			&:hover .nav-title, & .nav-title.active {
				background-image: url($images-root + "/misc/nav_background.jpg");
				text-decoration: none;
			}
			.sub-hide {
				-moz-box-shadow: 8px 8px rgba(0, 0, 0, 0.2);
				-webkit-box-shadow: 8px 8px rgba(0, 0, 0, 0.2);
				background: 20px 0 $subnav-bg-color;
				border-bottom: 4px solid $subnav-bottom-border-color;
				box-shadow: 8px 8px rgba(0, 0, 0, 0.2);
				display: none;
				padding: 5px 0 0;
				position: absolute;
				z-index: 2000;
				> li {
					border-top: 1px solid $subnav-divider-color;
					> a {
						background: none;
						border: none;
						color: $subnav-text-color;
						display: block;
						font: 14px 'Droid Sans', serif;
						text-transform: uppercase;
						padding: 10px 17px;
						line-height: 20px;
					}
					&:hover {
						a {
							background: $subnav-hover-color;
							text-decoration: none;
						}
					}
				}
			}
			&:hover > ul {
				display: block;
			}
		}
	}
}
header {
	.nav-collapse {
		z-index: 9999;
	}
	.logo {
		h2 {
			> a {
				@include imgtxt;
				background-image: url($images-root + "/catloaf-logo.png");
				background-size: auto 100%;
				height: 80px;
				line-height: 80px;
				width: 150px;
			}
		}
	}
}
footer {
	@mixin plain-text {
		display: block;
		color: $footer-text-color;
		padding-left: 40px;
		line-height: 20px;
		font-size: 12px;
	}
	background: #3b3b3b url($images-root + "/misc/footer_background_top.png") repeat-x top left;
	font-size: 11px;
	line-height: 18px;
	margin: 60px 0 0 0;
	.row-fluid {
		padding-top: 34px;
		.title {
			background: url($images-root + "/misc/footer_title_bckg.png") no-repeat left center;
			border: none;
			padding: 0 0 20px 0;
			h5 {
				color: $footer-header-text-color;
				text-transform: capitalize;
				padding: 17px 0 0 40px;
			}
		}
		> section, > section > div {
			> p, > address {
				@include plain-text;
			}
			> ul {
				> li {
					[class^="fa-"].fa-lg, [class*=" fa-"].fa-lg {
						color: $footer-link-text-color;
					}
					> span {
						@include plain-text;
					}
					> a, > span > a {
						color: $footer-link-text-color;
						font-size: 12px;
						line-height: 20px;
						&:hover {
							color: $footer-link-text-hover-color;
						}
					}
				}
				&.socialicons-footer {
					padding-left: 38px;
					> li {
						> a {
							margin-left: 6px;
							padding: 5px;
						}
					}
				}
				.tweet_text, .tweet_time {
					> a {
						&:hover {
							color: $footer-tweet-text-link-hover-color;
						}
					}
				}
			}
		}
	}
	.navbar-inner {
		.footer-nav {
			> li {
				display: inline-block;
				float: none;
				text-transform: uppercase;
				margin-left: 5px;
				&:first-child {
					> a {
						border: none;
					}
				}
				> a {
					background: none;
					border: none;
					border-left: 1px solid $footer-nav-divider-color;
					color: $footer-nav-text-color;
					display: block;
					line-height: 8px;
					padding: 0 0 0 5px;
					text-align: right;
					&:hover {
						background: none;
						color: $main-color;
					}
				}
			}
		}
	}
}
.title-breadcrumbs {
	background: $titlebox-bg-color;
	border: 10px solid $titlebox-border-color;
	border-left: none;
	border-right: none;
	.breadcrumbs {
		background: $breadcrumbs-bg-color;
		border-top: 1px solid $breadcrumbs-highlight-color;
		box-shadow: 0 -8px rgba(0, 0, 0, 0.2);
		display: inline-block;
		margin-top: 20px;
		padding: 4px 8px;
		.socialized {
			@include clearing;
			> li {
				float: left;
				padding: 10px 0 0 10px;
			}
			#rss-button > a {
				color: #fff;
			}
		}
		
	}
}
.content {
	&-section {
		padding-bottom: 30px;
		@include clearing;
		> figure {
			float: right;
		}
		> ul {
			padding-bottom: 10px;
			> li {
				list-style: disc inside;
  				padding-left: 30px;
			}
		}
		> ol {
			padding-bottom: 10px;
			> li {
				list-style: decimal inside;
  				padding-left: 30px;
			}
		}
		> p {
			padding-bottom: 10px;
		}
		> address {
			padding-left: 30px;
		}
		b, h4 {
		    font-weight: bold;
		}
		h5 {
		    font-style: italic;
        }
		> form {
			> fieldset {
				> p {
					> input, > textarea {
						box-sizing: border-box;
						padding: 15px 5px;
						width: 100%;
						&.error {
							border-color: $error-color;
							background: lighten($error-color,40%);
							color: $error-color;
						}
					}
				}
			}
			> ul {
				border: 1px solid darken($error-message-bg-color,20%);
				background: lighten($error-message-bg-color,40%);
				color: $error-color;
				font-weight: bold;
				padding: 10px;
				&:empty {
					border-width: 0;
					padding: 0;
				}
				> li {
					padding: 5px 0;
				}
			}
		}
		.separator > a > img {
		    padding-right: 5px;
		}
	}
	.socialicons {
		&.fa-ul {
			padding-left: 15px;
		}
		> li {
			padding: 10px;
			list-style: none;
		}
	}
	.blog {
	    p {
	        margin-bottom: 10px;
	    }
        .tr-caption {
            font-weight: bold;
        }
        .tr-caption-container {
            margin-top: 15px;
            margin-bottom: 15px;
        }
        > ul {
            margin: 12px;
        }
        > ul > li, > div > ul > li {
            list-style: disc inside;
            margin-left: 30px;
        }
        > ul > ul > li {
            list-style: disc inside;
            margin-left: 50px;
        }
        > b, > div > b, em {
            font-weight: bold;
        }
        > i, > div > i {
            font-style: italic;
        }
	}
	[class^="fa-"].fa-lg, [class^="fa-"].fa-lg, [class*=" fa-"].fa-lg, [class*=" fa-"].fa-lg {
		padding-right: 5px;
		position: relative;
		top: 5px;
	}
}
.stars {
	> li {
		background: url($images-root + "/star.png") no-repeat 0 -7px;
		color: #7a7a7a;
		list-style: none outside none;
		margin: 5px 0;
		padding-left: 36px;
	}
}
.bullets {
	> li {
		list-style: disc inside;
		padding-left: 30px;
	}
}
.blog-date {
    float: right;
    border-style: groove;
    border-width: 1px;
    margin: 5px;
    padding: 2px;
}
hr.blog-separator {
    border-width: 5px;
    border-color: #eee;
}
.breadcrumbs {
    > a {
        color: #fff;
    }
}
.pagination {
    font-size: 100%;
    .paginate-prev {
        float: left;
    }
    .paginate-next {
        float: right;
     }
     > .row {
        margin-left: 30px;
     }
}
.testimonial-carousel {
	> li {
		> blockquote {
			background: url($images-root + "/misc/quotes.png") no-repeat 0 10px;
			display: inline-block;
			font-size: 18px;
			line-height: 36px;
			padding-left: 80px;
		}
	}
}
.slide-controls {
	display: inline-block;
	float: right;
	margin: 10px 0;
	text-align: right;
	width: 75px;
	> li {
		display: inline-block;
		float: left;
		width: 30px;
		> a {
			@include imgtxt;
			display: block;
			height: 24px;
			width: 24px;
			&.prev.carousel-disabled {
				background-image: url($images-root + "/misc/prev_disabled_button.png");
			}
			&.prev {
				background-image: url($images-root + "/misc/previous_button.png");
			}
			&.next.carousel-disabled {
				background-image: url($images-root + "/misc/next_disabled_button.png");
			}
			&.next {
				background-image: url($images-root + "/misc/next_button.png");
			}
			&:hover {
				text-decoration: none;
			}
		}
	}
}
.instruments {
	> li {
		display: inline;
		> a {
			display: inline-block;
			text-align: center;
			> img {
				display: block;
			}
		}
	}
}
.demo-video {
	margin: 0 auto;
	width: 480px;
}
.fretuoso-logo {
	text-align: center;
	padding-top: 10px;
}
.devices {
	float: left;
	height: 644px;
	padding-top: 20px;
	position: relative;
	width: 540px;
	.ipad {
		background: url($images-root + "/ipad.png") no-repeat;
		height: 409px;
		position: absolute;
		width: 530px;
		> img {
			height: 332px;
			left: 48px;
			position: relative;
			top: 36px;
			width: 434px;
		}
	}
	.iphone {
		background: url($images-root + "/iphone.png") no-repeat;
		height: 450px;
		left: 50px;
		position: absolute;
		top: 123px;
		width: 246px;
		> img {
			height: 282px;
			left: 31px;
			position: relative;
			top: 82px;
			width: 188px;
		}
	}
	.appicon {
		bottom: 0;
		position: absolute;
		right: 28px;
	}
	.appcenter {
		position: absolute;
		bottom: 0;
		width: 250px;
	}
	.appstore {
		@include imgtxt;
		background: url($images-root + "/app-store.png") no-repeat;
		height: 92px;
		line-height: 92px;
		margin: 0 auto;
		width: 260px;
	}
}
.features {
	margin-left: 550px;
}
.demo-video {
	width: 480px;
	height: 360px;
	> iframe {
		width: 100%;
		height: 100%;
	}
}
.da-slider {
	min-height: 470px;
	.da-slide {
		> h2 {
			white-space: normal;
			text-shadow: .05em .05em .05em #fff;
			&.trainer-header {
				font-size: 130%;
				line-height: 130%;
			}
		}
		.da-img {
			top: 0;
			> a {
				display: block;
			}
			&.textsFromImg {
				min-width: 42%;
				top: 9%;
			}
		}
		.da-link {
			top: 75%;
			margin-left: 10%;
			> a {
				@include imgtxt;
				background-image:url(/cdn-cgi/image/format=auto,onerror=redirect/img/slider/appstore.png);
				height: 70px;
				line-height: 70px;
				width: 237px;
			}
		}
	}
}
.all-instruments {
	text-align: center;
}
.alert-success {
	display: none;
}
/* SCROLL TO TOP
   ================================================== */

.scrollup {
	display: none;
	position: fixed;
	bottom: 23px;
	right: 15px;
	opacity: 0.5;
}

aside.scrollup {
	> p {
		> a {
			@include imgtxt;
			background:url($images-root + "/misc/to_top.png");
			width:36px;
			height:27px;
		}
	}
}
	
@media handheld, only screen and (max-width: 1065px) {
	.da-slider {
		min-height:410px;
		.da-slide {
			> h2 {
				line-height: 140%;
				font-size: 230%;
			}
			> p {
				top: 29%;
				&.da-img {
					top: 4%;
					&.textsFromImg {
						height: 62%;
						min-width: 400px;
						top: 14%;
					}
				}
				&.da-link {
					top: 70%;
					> a {
						background-size: 100%;
						height: 48px;
						line-height: 48px;
						width: 166px;
					}
				}
			}
		}
	}
	.all-instruments {
		> img {
			width: 100%;
		}
	}
}
@media handheld, only screen and (max-width: 815px) {
	.row {
		padding: 0 5px;
	}
	header {
		nav {
			position: absolute;
			right: 0;
			top: 0;
		}
		.btn-navbar {
			position: absolute;
			right: 0;
			top: 0;
		}
		.navbar {
			.nav-collapse {
				background: $mobile-navbar-bg-color;
				position: absolute;
				width: 100%;
				top: 0;
				left: 0;
				.main-nav {
					float: none;
					> li {
						float: none;
						.nav-title {
							box-shadow: 
							  0 1px 2px #fff, /*bottom external highlight*/
							  0 -1px 1px #666, /*top external shadow*/ 
							  inset 0 -1px 1px rgba(0,0,0,0.5), /*bottom internal shadow*/ 
							  inset 0 1px 1px rgba(255,255,255,0.8); /*top internal highlight*/
							;
							margin: 0;
							padding: 6px 0;
						}
						&:hover .nav-title, & .nav-title.active {
								background: $mobile-navbar-item-active-color;
						}
						> ul {
							background: $mobile-navbar-bg-color;
							box-shadow: 3px 3px 5px 3px rgba(0, 0, 0, 0.2);
							padding: 0;
							position: static;
							> li {
								> a {
									color: $mobile-subnav-text-color;
								}
							}
						}
					}
				}
			}
			.btn-navbar {
				position: absolute;
				z-index: 9999;
			}
		}
	}
	footer {
		.navbar {
			display: none;
		}
	}
	.devices {
		float: none;
		width: auto;
	}
	.features {
		margin: 0;
	}
	.da-slider {
		min-height:310px;
		.da-slide {
			> p {
				//top: 35%;
				&.da-img {
					&.textsFromImg {
						height: 69%;
						min-width: 320px;
					}
				}
			}
		}
	}
}
@media handheld, only screen and (max-width: 615px) {
	.da-slider {
		.da-slide {
			> h2 {
				font-size: 210%;
				&.trainer-header {
					min-width: 70%;
					top: 4%;
					width: 70%;
				}
			}
			> p {
				&.da-img {
					min-width: 43%;
					top: 13%;
					&.textsFromImg {
						height: 77%;
					}
				}
			}
		}
	}
}
@media handheld, only screen and (max-width: 495px) {
	header {
		.logo {
			h2 {
				> a {
					height: 35px;
					line-height: 35px;
				}
			}
		}
	}
	.demo-video {
		width: 470px;
		height: 360px;
	}
	.da-slider {
		min-height: 162px;
		.da-slide {
			> h2 {
				font-size: 100%;
				&.trainer-header {
					font-size: 113%;
					display: none;
				}
			}
			> p {
				top: 27%;
				&.da-img {
					min-width: 31%;
					top: 13%;
					&.textsFromImg {
						height: 70%;
						min-width: 41%;
						top: 3%;
					}
				}
				&.da-logo {
					top: 3%;
				}
				&.da-link {
					top: 55%;
					min-width: 29%;
					width: 29%;
					> a {
						width: 102px;
					}
				}
			}
		}
	}
	.devices {
		height: 665px;
		.appcenter {
			bottom: auto;
			position: absolute;
			top: 0;
			left: 14%;
		}
		.appstore {
			margin: 0;
		}
	}
}
@media handheld, only screen and (max-width: 380px) {
	.demo-video {
		width: 310px;
		height: 160px;
	}
	.breadcrumbs {
		#gplus-button {
			display: none;
		}
	}
	.da-slider {
		min-height: 160px;
		.da-slide {
			> p {
				top: 29%;
				&.da-img {
					min-width: 39%;
					top: 10%;
					> a {
						top: 50%;
						width: auto;
					}
					&.textsFromImg {
						top: 15%;
						> a {
							height: 50%
						}
					}
				}
				&.da-logo {
					top: 3%;
				}
				&.da-link {
					min-width: 30%;
					top: 51%;
					width: 30%;
					> a {
						height: 29px;
						line-height: 29px;
						width: 96px;
					}
				}
			}
			> h2 {
				font-size: 109%;
				&.trainer-header {
					font-size: 100%;
					top: 3%;
					display: none;
				}
			}
		}
	}
}
